/**
 * Created by jiachenpan on 16/11/18.
 */
import fun_aes from "./aes.js";
import { SERVERURL } from "./config";

export function parseTime(time, cFormat) {
  if (arguments.length === 0) {
    return null;
  }
  const format = cFormat || "{y}-{m}-{d} {h}:{i}:{s}";
  let date;
  if (typeof time === "object") {
    date = time;
  } else {
    if (typeof time === "string" && /^[0-9]+$/.test(time)) {
      time = parseInt(time);
    }
    if (typeof time === "number" && time.toString().length === 10) {
      time = time * 1000;
    }
    date = new Date(time);
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay(),
  };
  const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
    let value = formatObj[key];
    // Note: getDay() returns 0 on Sunday
    if (key === "a") {
      return ["日", "一", "二", "三", "四", "五", "六"][value];
    }
    if (result.length > 0 && value < 10) {
      value = "0" + value;
    }
    return value || 0;
  });
  return time_str;
}

export function formatTime(time, option) {
  if (("" + time).length === 10) {
    time = parseInt(time) * 1000;
  } else {
    time = +time;
  }
  const d = new Date(time);
  const now = Date.now();

  const diff = (now - d) / 1000;

  if (diff < 30) {
    return "刚刚";
  } else if (diff < 3600) {
    // less 1 hour
    return Math.ceil(diff / 60) + "分钟前";
  } else if (diff < 3600 * 24) {
    return Math.ceil(diff / 3600) + "小时前";
  } else if (diff < 3600 * 24 * 2) {
    return "1天前";
  }
  if (option) {
    return parseTime(time, option);
  } else {
    return (
      d.getMonth() +
      1 +
      "月" +
      d.getDate() +
      "日" +
      d.getHours() +
      "时" +
      d.getMinutes() +
      "分"
    );
  }
}

// 格式化时间
export function getQueryObject(url) {
  url = url == null ? window.location.href : url;
  const search = url.substring(url.lastIndexOf("?") + 1);
  const obj = {};
  const reg = /([^?&=]+)=([^?&=]*)/g;
  search.replace(reg, (rs, $1, $2) => {
    const name = decodeURIComponent($1);
    let val = decodeURIComponent($2);
    val = String(val);
    obj[name] = val;
    return rs;
  });
  return obj;
}

/**
 * @param {Sting} input value
 * @returns {number} output value
 */
export function byteLength(str) {
  // returns the byte length of an utf8 string
  let s = str.length;
  for (var i = str.length - 1; i >= 0; i--) {
    const code = str.charCodeAt(i);
    if (code > 0x7f && code <= 0x7ff) s++;
    else if (code > 0x7ff && code <= 0xffff) s += 2;
    if (code >= 0xdc00 && code <= 0xdfff) i--;
  }
  return s;
}

export function cleanArray(actual) {
  const newArray = [];
  for (let i = 0; i < actual.length; i++) {
    if (actual[i]) {
      newArray.push(actual[i]);
    }
  }
  return newArray;
}

export function param(json) {
  if (!json) return "";
  return cleanArray(
    Object.keys(json).map((key) => {
      if (json[key] === undefined) return "";
      return encodeURIComponent(key) + "=" + encodeURIComponent(json[key]);
    })
  ).join("&");
}

export function param2Obj(url) {
  const search = url.split("?")[1];
  if (!search) {
    return {};
  }
  return JSON.parse(
    '{"' +
      decodeURIComponent(search)
        .replace(/"/g, '\\"')
        .replace(/&/g, '","')
        .replace(/=/g, '":"')
        .replace(/\+/g, " ") +
      '"}'
  );
}

export function html2Text(val) {
  const div = document.createElement("div");
  div.innerHTML = val;
  return div.textContent || div.innerText;
}

export function objectMerge(target, source) {
  /* Merges two  objects,
     giving the last one precedence */

  if (typeof target !== "object") {
    target = {};
  }
  if (Array.isArray(source)) {
    return source.slice();
  }
  Object.keys(source).forEach((property) => {
    const sourceProperty = source[property];
    if (typeof sourceProperty === "object") {
      target[property] = objectMerge(target[property], sourceProperty);
    } else {
      target[property] = sourceProperty;
    }
  });
  return target;
}

export function toggleClass(element, className) {
  if (!element || !className) {
    return;
  }
  let classString = element.className;
  const nameIndex = classString.indexOf(className);
  if (nameIndex === -1) {
    classString += "" + className;
  } else {
    classString =
      classString.substr(0, nameIndex) +
      classString.substr(nameIndex + className.length);
  }
  element.className = classString;
}

export const pickerOptions = [
  {
    text: "今天",
    onClick(picker) {
      const end = new Date();
      const start = new Date(new Date().toDateString());
      end.setTime(start.getTime());
      picker.$emit("pick", [start, end]);
    },
  },
  {
    text: "最近一周",
    onClick(picker) {
      const end = new Date(new Date().toDateString());
      const start = new Date();
      start.setTime(end.getTime() - 3600 * 1000 * 24 * 7);
      picker.$emit("pick", [start, end]);
    },
  },
  {
    text: "最近一个月",
    onClick(picker) {
      const end = new Date(new Date().toDateString());
      const start = new Date();
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
      picker.$emit("pick", [start, end]);
    },
  },
  {
    text: "最近三个月",
    onClick(picker) {
      const end = new Date(new Date().toDateString());
      const start = new Date();
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
      picker.$emit("pick", [start, end]);
    },
  },
];

export function getTime(type) {
  if (type === "start") {
    return new Date().getTime() - 3600 * 1000 * 24 * 90;
  } else {
    return new Date(new Date().toDateString());
  }
}

export function debounce(func, wait, immediate) {
  let timeout, args, context, timestamp, result;

  const later = function () {
    // 据上一次触发时间间隔
    const last = +new Date() - timestamp;

    // 上次被包装函数被调用时间间隔 last 小于设定时间间隔 wait
    if (last < wait && last > 0) {
      timeout = setTimeout(later, wait - last);
    } else {
      timeout = null;
      // 如果设定为immediate===true，因为开始边界已经调用过了此处无需调用
      if (!immediate) {
        result = func.apply(context, args);
        if (!timeout) context = args = null;
      }
    }
  };

  return function (...args) {
    context = this;
    timestamp = +new Date();
    const callNow = immediate && !timeout;
    // 如果延时不存在，重新设定延时
    if (!timeout) timeout = setTimeout(later, wait);
    if (callNow) {
      result = func.apply(context, args);
      context = args = null;
    }

    return result;
  };
}

/**
 * This is just a simple version of deep copy
 * Has a lot of edge cases bug
 * If you want to use a perfect deep copy, use lodash's _.cloneDeep
 */
export function deepClone(source) {
  if (!source && typeof source !== "object") {
    throw new Error("error arguments", "deepClone");
  }
  const targetObj = source.constructor === Array ? [] : {};
  Object.keys(source).forEach((keys) => {
    if (source[keys] && typeof source[keys] === "object") {
      targetObj[keys] = deepClone(source[keys]);
    } else {
      targetObj[keys] = source[keys];
    }
  });
  return targetObj;
}

export function uniqueArr(arr) {
  return Array.from(new Set(arr));
}

// export function createUniqueString() {
//   const timestamp = +new Date() + ''
//   const randomNum = parseInt((1 + Math.random()) * 65536) + ''
//   return (+(randomNum + timestamp)).toString(32)
// }

// export function hasClass(ele, cls) {
//   return !!ele.className.match(new RegExp('(\\s|^)' + cls + '(\\s|$)'))
// }
// export function addClass(ele, cls) {
//   if (!hasClass(ele, cls)) ele.className += ' ' + cls
// }
// export function removeClass(ele, cls) {
//   if (hasClass(ele, cls)) {
//     const reg = new RegExp('(\\s|^)' + cls + '(\\s|$)')
//     ele.className = ele.className.replace(reg, ' ')
//   }
// }
export function Encrypt(word) {
  var key = fun_aes.CryptoJS.enc.Utf8.parse("1010101010101010");
  var iv = fun_aes.CryptoJS.enc.Utf8.parse("6666666666666666");
  var encrypted = fun_aes.CryptoJS.AES.encrypt(JSON.stringify(word), key, {
    iv: iv,
    mode: fun_aes.CryptoJS.mode.CBC,
    padding: fun_aes.CryptoJS.pad.ZeroPadding,
  });
  return encrypted.toString();
}

export function fileAdd(file) {
  return new Promise((resolve) => {
    // 判断是否为图片文件
    if (file.type.indexOf("image") === -1) {
      // //console.log('请选择图片文件')
    } else {
      const reader = new FileReader();
      const image = new Image();
      // const _this = this
      reader.readAsDataURL(file);
      reader.onload = function () {
        file.src = this.result;
        image.onload = function () {
          const width = image.width;
          const height = image.height;
          file.width = width;
          file.height = height;
          // _this.imgL = file.src // 页面上显示所选择的图片
        };
        // //console.log(file)
        image.src = file.src;
        imgCompress(file, { quality: 0.6 }).then((res) => resolve(res));
      };
    }
  });
}
// 图片压缩 压缩之后的base64编码格式文件经过convertBase64UrlToBlob()方法转换成file文件并通过promise返回
export function imgCompress(path, obj) {
  // path是指上传的图片，obj是压缩的品质，越低越模糊
  // //console.log('压缩之前')
  return new Promise((resolve) => {
    // const _this = this // 这里的this 是把vue的实例对象指向改变为_this
    var img = new Image();
    img.src = URL.createObjectURL(path);
    // //console.log(path.name, 'path')

    img.onload = function () {
      var that = this; // 这里的this 是把img的对象指向改变为that
      // 默认按比例压缩
      var w = that.width;
      var h = that.height;
      var scale = w / h;
      w = obj.width || w;
      h = obj.height || w / scale;
      var quality = 0.7; // 默认图片质量为0.7
      // 生成canvas
      var canvas = document.createElement("canvas");
      var ctx = canvas.getContext("2d");
      // 创建属性节点
      var anw = document.createAttribute("width");
      anw.nodeValue = w;
      var anh = document.createAttribute("height");
      anh.nodeValue = h;
      canvas.setAttributeNode(anw);
      canvas.setAttributeNode(anh);
      ctx.drawImage(that, 0, 0, w, h);
      // 图像质量
      if (obj.quality && obj.quality <= 1 && obj.quality > 0) {
        quality = obj.quality;
      }
      // quality值越小，所绘制出的图像越模糊
      var base64 = canvas.toDataURL(path.type, quality);
      // 回调函数返回base64的值
      // console.log(path)
      var index = path.name.lastIndexOf(".");
      var pathArr = path.type.split("/");
      var pathName = "";
      if (index > 0) {
        pathName = path.name;
      } else {
        pathName = path.name + pathArr[1];
      }
      var urlFile = convertBase64UrlToBlob(base64, pathName); // 这个地方的处理是为了把压缩的base64转化为对象，获得压缩后图片的大小size，方便对压缩后的图片再次进行判断；
      // //console.log(urlFile, base64, 'base64')
      // _this.partitionBase = base64.split(',')[1]
      // _this.imgType = '.' + urlFile.type.split('/')[1]

      resolve(urlFile);
    };
  });
}

// 将base64码转化为file（Blob）
// 此处函数对压缩后的base64经过处理返回{size: "", type: ""}
export function convertBase64UrlToBlob(dataurl, filename) {
  const arr = dataurl.split(",");
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, {
    type: mime,
  });
}

// 图片转base64
export function urlToBase64(url) {
  return new Promise((resolve) => {
    const NewImage = new Image();
    NewImage.setAttribute("crossOrigin", "Anonymous");
    NewImage.src = `${SERVERURL}/wechat/getImageData?url=${url}`;
    NewImage.onload = function () {
      // 默认按比例压缩
      const w = this.width;
      const h = this.height;
      // 生成canvas
      const canvas = document.createElement("canvas");
      canvas.width = w;
      canvas.height = h;
      const ctx = canvas.getContext("2d");
      ctx.drawImage(NewImage, 0, 0, w, h);
      const base64 = canvas.toDataURL(`image/jpeg`);
      resolve(base64);
    };
  });
}

/*
 *  description: 在vue中使用的防抖函数
 *  param fnName {String}  函数名
 *  param time {Number}    延迟时间
 *  return: 处理后的执行函数
 */
export function VueDebounce(fnName, time) {
  let timeout = null;
  return function () {
    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => {
      this[fnName]();
    }, time);
  };
}
// 节流
export function throttle(fn, wait) {
  var prev = Date.now();
  return function () {
    var context = this;
    var args = arguments;
    var now = Date.now();

    if (now - prev > wait) {
      fn.apply(context, args);
      prev = Date.now();
    }
  };
}
